import { Link, useMatch, useResolvedPath } from "react-router-dom"


export default function Header() {
// let role=localStorage.getItem('role')
let role=localStorage.getItem('role')
let names;
if(role==1)
{
names='SuperAdmin'
}
else if(role==2)
{
  names='SchoolAdmin'
}
else if(role==3)
{
  names='BusinessOwner'
}
else
{
  names='Student_Profile'
}
let newurl=window.location.pathname; //yields: "/js" (where snippets run)
// console.log(role)
  return (
    <nav className="page-header-top">
      <ul style={{float:'left'}}>
      <Link to="/" className="site-title">
        <img src="logo.png" alt="" className="logo"/>
        </Link>
            </ul>
            
      
      <ul>
        <CustomLink to="/about">About</CustomLink>
        <CustomLink to="/Contact">Contact</CustomLink>
        
        <CustomLink to="/Services">Service</CustomLink>
        <li><a href="http://gxd6784.uta.cloud/blog/" target="_blank">Blog</a></li>
        {!role && 
        <CustomLink to="/Login">Login</CustomLink>
        }
{role==4 && <CustomLink to="/Student">Dashboard</CustomLink>}
{role && <CustomLink to={names}>Profile</CustomLink>}
{role &&      
                
                <CustomLink to="/logout">Logout</CustomLink>
}
      </ul>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
import React from "react"
import  {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";

export default function Transactions()
{

    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const [count, setCount] = useState(0)  

    
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          axios.get(`https://gxd6784.uta.cloud/backend/apicrud/transactions.php`)
          .then(res => {
            console.log(res.data.userlist.userdata)
            setuser(res.data.userlist.userdata);
          })
        } catch (error) { throw error;}    
      }
    
      

    return (
        <>
         <div className="row">
        <div className="one-thirds">
            <div className="row">
                <h2 className="">Payment  History</h2>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    {isuser.map((item,index)=>(


<tr>
                        <td>{item.name}</td>
                        <td>{item.amount} </td>
                        <td className="add">{item.status}</td>
                        
                    </tr>


))}
               </table>

            </div>
        </div>
        
    </div>
        </>
    )
}
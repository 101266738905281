import React from "react";

export default function BusinessOwner()
{
    const width0={
        width:'100%'
    };

    return (
        <>
        <h2 className="text-center">Business Owner</h2>
    <div className="row">
        <div >
            <div className="row">
                <div></div>
                <div className="field button" >
                <div className="row"> 
                    
                    <a href="/Add_Advertisement" style={{marginRight:'10px',paddingLeft:'0px'}}>Post Advertisements</a>
                </div>
</div>
                <div className="field button ">
                    
                <div className="row"> 
                    
                    <a href="/Advertisement" style={{marginRight:'10px',paddingLeft:'0px'}}> Advertisements</a>
                </div> 
                </div>  
                <div></div>
            </div>
            <div className="row">
                <div ></div>
                <div className="field button  " >
                    <div className="row">
                        <a href="/InventoryAvailable">
                            Inventory
                        </a>
                    </div>
                    
                </div>
                <div className="field button ">
                    
                </div>  
                
            </div>
            <div className="row">
                <div ></div>
                <div className="field button  " ><div className="row">
                        <a href="/transactions">
                        Transactions
                        </a>
                    </div>
           
                </div>
                <div className="field button ">
                    
                </div>  
                
            </div>
        </div>
        
        <div className="chat">
            
            <div className="input button">
                
<a href="https://gxd6784.uta.cloud/backend/chat/index.php">Chat with an expert</a>
            </div> 
    </div>
    </div>
        </>
    )
}
import React from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import useToken from './useToken';
import { redirect } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {omit} from 'lodash'
import { batch } from "react-redux";


export default function Payment()
{

  const [values, setValues] = useState({});
  //Errors
  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {
    //A function to validate each input values

    switch (name) {
      case 'email':
        if(
            !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
        ){
            setErrors({
                ...errors,
                email:'Enter a valid email address'
            })
        }else{

            let newerrorob = omit(errors, "email");
            setErrors(newerrorob);
            
        }
    break;
    
        
        case 'card_number':
            if(
                !new RegExp(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/).test(value)
            ){
                setErrors({
                    ...errors,
                    password:'Please Enter Valid Card Details'
                })
            }else{

                let newerrorob = omit(errors, "card_number");
                setErrors(newerrorob);
                console.log(newerrorob)
            }
        break;
        
        default:
            break;
    }
}

      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        card_number: '',
        name: '',
        expiry_month:'',
        expiry_date:'',
        cvv:'',
        user_id:'',
        amount:'',
        status:'',
      });
      const onChangeValue = (event) => {
        event.persist();

        let name = event.target.name;
        let val = event.target.value;
        
        validate(event,name,val);
        setuserInfo({
          ...userInfo,
          [event.target.name]:event.target.value
        });
      } 
      const submitUser = async(event) => {
        try {
          event.preventDefault();
          event.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/payment.php`, { 
              user_id: localStorage.getItem('id'),
            card_number: userInfo.card_number,
        name: userInfo.name,
        expiry_month:userInfo.expiry_month,
        expiry_date:userInfo.expiry_date,
        cvv:userInfo.cvv,
        amount:localStorage.getItem('total'),
        status:1,
        cart_id:localStorage.getItem('cart_id')
          })
          .then(res => {
            console.log(res.data);
           // navigate(`/`);
           if(res.data.status==1)
           {
           window.location.href='/Payment_success';
           }
           alert(res.data.message);
            return;
           })
        } catch (error) { throw error;}    
      }
  
    return (
        <>
        <h2 className="text-center">Payment</h2>
    <div className="row">
        <div className="full">
            <div className="row form">
                <div className=""></div>
                <form onSubmit={submitUser}>
                <div className="field input  ">
                    
                    <label for="">Credit Card Number</label>
                    <input type="text" name="card_number" minLength={16}  id="card_number" onChange={onChangeValue} placeholder="12345xxxxxxx5" style={{width: '100%'}} required/>
                    {
        errors && 
        
        <h3 style={{color:"red",background:'white'}}>{errors.card_number}</h3>

      }
                </div>
                <div className="field input">
                    <label for="">Card Holder Name</label>
                    <input type="text" name="name" id="name" placeholder="Name"  onChange={onChangeValue} style={{width: '100%'}}/>
                   
                </div>
                <div>
                <div className="field input">
                    <label for="">Expiry</label>
                    <div >
                        <input type="text" name="expiry_month" id="expiry_month" onChange={onChangeValue} placeholder="01" style={{width: '10%'}}/>
                        <input type="text" name="expiry_date" is="expiry_date" onChange={onChangeValue} placeholder="28" style={{width: '10%'}}/>
                    
                        <label for="" >Cvv</label>
                    
                        <input type="text" required minLength={3} maxLength={3} name="cvv" id="cvv" placeholder="0xx" onChange={onChangeValue} style={{width: '20%'}}/>
                        
                    </div>
                </div>
            </div>
            <div className="field button">
                    <div className="row"> 
                    <input type="submit" value="Pay" style={{marginRight:'10px',paddingLeft:'0px'}} />
                    </div>
                    </div>  
            </form>
                <div></div>
            </div>
            
        </div>
       
    </div>
        </>
    )
}
import React  from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function StudentProfile()
        {
          const [data, setData] = useState([]);
          const userdata= JSON.parse(localStorage.getItem('user-info'));
let userdatas=(userdata);

          const navigate = useNavigate();
          const [userInfo, setuserInfo] = useState({
            post: '',
            club_image: '',
            user_id:'',
            item_id:'',
            price:'',
            post_title:''
            
          });
          
                  
          


          
          
          const submitcart = async(id,price) => {
            try {
              
             console.log(userInfo)
             let item={user_id: localStorage.getItem('id'),
             item_id: id,
             price:price};
             let result = await fetch("https://gxd6784.uta.cloud/backend/api/addCart  ", {
      method: 'POST',
      body: JSON.stringify(item),
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json '
      }
    })
    console.log(result)
              
            } catch (error) { throw error;}    
          }
        


          const [orderdata, setorderdata] = useState([]);
      const alluser = async (ids) => {
        try {
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/get_orders.php`,
          {
            user_id:localStorage.getItem('id')
          })
          .then(res => {
            console.log(res.data)
            setorderdata(res.data.userlist.userdata);
          })
        } catch (error) { throw error;}    
      }

      const deleteConfirm = (id) => {
        if (window.confirm("Are you sure?")) {
          deleteUser(id);
        }
      };
      useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
        
      }, []); 
      const deleteUser = async (id) => {
        try {
          
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/returnorder.php`,
          {
            id:id
          })
          .then(res => {
            alert('Item Returned SuccesFUlly');
          })
    
    
        } catch (error) { throw error;}    
      }
    
    
            const newstyle={
                fontWeight:'bold',
                
                fontFamily: 'sans-serif'
            }
            const fonts={
                fontFamily: 'sans-serif',
                fontSize:'150%'
            }
            return (
                <>
    <div className="row">
    <div className="">
    </div>
        
        <div className="box">
            <div className="form login">
            
                    <div className="field button">
                
                    <div className="row"> 
                        Name  : {userdatas.name}    
                        <br></br>    
                        Email : {userdatas.email}
                        <br></br>
                        Phone : {userdatas.contact}

                    </div>
                    </div>
            </div>
        </div>
        
        <div>
    </div>
    
  
</div> 


<div className="row" style={{ margin:'0px',padding:'0px'}}>


</div>
<div className="row padding_25" >



<table className="timetable_sub">
            
                            <thead>
                                <tr>
                                    <th>Item  Name</th>
                                    <th>Ordered Date</th>
                                    <th>Return Order</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {orderdata.map((item1,index)=>(
      <tr className="rem1">
                                    <td className="invert">{item1.item_name}</td>
                          <td>  <h4>{item1.date}</h4>
                            
                            <p>{item1.user}</p>
                          </td>
                                    <td className="invert">
                                        <div className="rem">
                                            <div className="close1" onClick={() => deleteConfirm(item1.id)}>&times; </div>
                                            
                                        </div>
    
                                    </td>
                                </tr>
                                ))} 

                            </tbody>
                        </table>
        


</div>

<div className="row padding_25">

</div>
<div className="row">
  <div className="">
</div>
    <div>
      
    </div>
    </div>
    </>
            )
            }
import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";

export default function StudentOrder()
{
    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
    
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          axios.get(`https://gxd6784.uta.cloud/backend/apinew/v2/get_orders.php`)
          .then(res => {
            console.log(res.data)
            setuser(res.data.user);
          })
        } catch (error) { throw error;}    
      }
    return (
        <>
        <h2 className="text-center">Order History of Students</h2>
    <div className="row">
        <div className="one-thirds">
        {isuser.map((item,index)=>(

            <div className="box" key={item.id}>
                <div className="box-body">
                    <h3>Confirmed</h3>
                    <p>Paid on: {item.date}</p>
                    <div className="row">
                        <div>
                            <img src={item.image} alt="" className="img-responsive item_image"/>
                            
                        </div>
                        <div>
                            <h4>{item.item}</h4>
                            
                            <p>{item.user}</p>
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        ))}
            
            
        </div>
       
        
    </div>

        </>
    )
}
import React from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import useToken from './useToken';
import { redirect } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';

export default function VerifyEmailOTP({ setToken })
{


  const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({
        email: '',
        otp: '',
      });

      const [count, setCount] = useState(0)  
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
      const authResult = new URLSearchParams(window.location.search); 
      const newemail = authResult.get('email')
      
      // const [token, setToken] = useState(getToken());
          
      // Inserting a new user into the Database.
      const submitUser = async(event) => {
        try {
          event.preventDefault();
          event.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/checkemailotp.php`, { 
              email:newemail,
            otp: userInfo.otp,
          })
          .then(response => {

            window.alert(response.data.message);

if(response.data.status==1)
{
window.location.href='/Login';
    axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/getuser.php`, { 
              id: localStorage.getItem('user_id'),
          })
          .then(res => {
            if(res.data.status==1)
            {
          //    window.location.href='/VerifyOTP';
          
            
            //
          

          // window.alert('Logedin Successfully!')
            return {}
        }});

          //////
          }
          else
        {
            window.location.href = '/Login';
        
          window.alert(response.data.error)
        }
        }
        
          
          )
        } catch (error) { throw error;
        }    
      }
  


    return (
        <>
<div className="row">
    <div className=""></div>
    <div className="">
        <div className="box">
            <div className="form login">

            {/* <h1 className="count">{count}</h1> */}


            {/* <button onClick={() => setCount(count + 1)}>Up</button>
            <button onClick={() => setCount(count - 1)}>Down</button> */}

            <form action="" onSubmit={submitUser}>
                <h4 className="text-center header">Verify OTP & Activate Your Account</h4>
                <div className="field input">
                    <label for="">Enter OTP send to your Email</label>
                    <input type="otp" name="otp" required placeholder="*****" onChange={onChangeValue} />
                </div>
                <div className="field button">
                <input type="submit" value="Verify OTP" style={{marginRight:'10px',paddingLeft:'0px'}} />  

                </div>
                <div className="pass_link">Forgot password? <a href="./forgotpass">Click to reset your password</a></div>
                <div className="signup_link"> New User?  <a href="./Register">SignUp</a>

                
                </div>    
            </form>
            </div>
        </div>
    </div>
    <div></div>
    
</div>
</>
 )
 }
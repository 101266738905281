import React  from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Studentclubs()
{
    const newstyle={
        fontWeight:'bold',
        fontFamily: 'sans-serif'
    }
    const fonts={
        fontFamily: 'sans-serif',
        fontSize:'150%'
    }


    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
    
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {

        try {
          (async () => {
            let result = await fetch("https://gxd6784.uta.cloud/backend/api/allclub", {
              method: 'POST'
            }
            );
            result = await result.json();
            setuser(result);
            
          })();

          
        } catch (error) { throw error;}    
      }
    
      
    


/////////////////



    const navigate = useNavigate();
    const [userInfo, setuserInfo] = useState({
      club_name: '',
      club_image: '',
    });
    const onChangeValue = (e) => {
      setuserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 
    // Inserting a new user into the Database.
    const submitUser = async(event) => {
      try {
        event.preventDefault();
        event.persist();
       
        axios.post(`https://gxd6784.uta.cloud/backend/apicrud/join_club.php`, { 
            club_name: userInfo.club_name,
          image: userInfo.club_image,
        })
        .then(res => {
          console.log(res.data);
          navigate(`/`);
          return;
         })
      } catch (error) { throw error;}    
    }


//////////
      

    return (
        <>
    <div>
        <h3 className="header text-center">Student clubs</h3>
    
    </div>
            <div className="row">
            {isuser.map((item,index)=>(
                <div className="field button" key={item.id} >
                    <img src={item.image} className="logo"  alt="" />
                    <input type="submit" className="full" value={item.club} />
                    
                </div>
                
            
            ))}
        
        
    </div>
        <div className="row">
                
                    
                    <div>
                </div>
                <div className="chat">
            
                    <div className="input button">
                        <input type="submit" value="Chat with an expert" />
        
                    </div>
            </div>
    </div>
</>
    )
    }
import React from 'react';
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from 'recharts';
  
  
export default function NewChart() {

  
  useEffect( () => {
    window.scrollTo(0, 0);
    alluser();
  }, []); 

  const [isuser, setuser] = useState([]);
  // let newdata=[];
  const alluser = async (ids) => {
    try {
        axios.get(`https://gxd6784.uta.cloud/backend/apicrud/fetchreportdata.php`, { 
          
        })
        .then(res => {
            
          let newdata=(res.data.userlist.userdata);
    setuser(res.data.userlist.userdata);
    
      })
    } catch (error) { throw error;}    
  }
let newdata={isuser};
const data = [
  {product: 'MacBook Pro', products: 400},
  {product: 'IPHONE 14 PRO	', products: 700},
  {product: 'BOOK', products: 200},
  {product: 'USB CABLE	', products: 1000}
];
  console.log(newdata);
  
return (


<BarChart width={600} height={500} data={newdata.isuser}>
    <Bar dataKey="products" fill="green" />
    <CartesianGrid stroke="#ccc" />
    <XAxis dataKey="product" />
    <YAxis />
  </BarChart>
);
}

import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SuperAdminChat()
{
    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        message: '',
        image: '',
      });
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
            axios.get(`https://gxd6784.uta.cloud/backend/apicrud/studentsuperadminmessages.php`, { 
              
            })
            .then(res => {
                
              console.log(res.data.userlist.messages);
        setuser(res.data.userlist.messages);
          })
        } catch (error) { throw error;}    
      }
  
      
      /////////////////////


      const submitUser = async(id) => {
        try {
          
        //  id.preventDefault();
        //   id.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/sendmessagetostudent.php`, { 
              message:userInfo.message,
              user_id:id
          })
          .then(res => {
            console.log(res)
      
        })
    }
    catch
    {

    }
}

    return (
        <>
        <div className="row" style={{ margin:'0px',padding:'0px'}}>
           
{isuser.map((item,index)=>(

<div style={{marginBottom: '5px'}}>
    <div className="box">
    Messages
        <div className="box-body">
        <p>{item.name} : {
            item.message
        }</p>
        {item.messag}
        </div>
    
            <div className="form login">
            {/* <form onSubmit={submitUser}> */}
                <div className="field input">
                    <input type="text" 
                                    onChange={onChangeValue}
                                    name="message"  placeholder="Message"  required />

                </div>
                
                
                <div className="field button">
                    <div className="row"> 
                    <input type="submit" value="Send Message to Student " onClick={() => submitUser(item.user_id)} style={{marginRight:'10px',paddingLeft:'0px'}} />
                    </div>
                    </div>
            {/* </form> */}
            
                    
            </div>
        </div>
        
</div>  
))}  

</div>
 <div className="row">
    <div className="">
    </div>
  
        
        <div>
    </div>
    
  
</div> 




  </>
  )
}
import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Business()
{
    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        post: '',
        club_image: '',
      });
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
              
      const [isuser, setuser] = useState([]);
      const [total, settotal] = useState([]);
      
      const alluser = async (ids) => {
        try {
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/businessowner.php`)
          .then(res => {
            console.log(res.data.userlist.userdata)
            setuser(res.data.userlist.userdata);
            
          })
        } catch (error) { throw error;}    
      }

      const deleteConfirm = (id) => {
        if (window.confirm("Are you sure to delete the Business Owner?")) {
          deleteUser(id);
        }
      };
      const deleteUser = async (id) => {
        try {
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/deleteusers.php`, { 
            user_id: id,
          })
          .then(res => {
            setuser([]);
            alluser();
            return;
           })
        } catch (error) { throw error;}    
      }
    return (
        <>
        
        <div className="row">
            <div className="shop_inner_inf">
                <div className="privacy about">
                   
                    <div className="checkout-right">
                        <h4>Business Owner </h4>
                        <table className="timetable_sub">
                            <thead>
                                <tr>
                                    <th>UserName</th>
                                    <th>Email</th>
                                    <th>Contact</th>
    
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                            {isuser.map((item,index)=>(

                                <tr className="rem1">
                                    <td className="invert-image"><a href="">{item.name}</a></td>
                                    
                                    <td className="invert">{item.email}</td>
                                    
                                    <td className="invert">
                                        <div className="quantity">
                                            <div className="quantity-select">
                                                <div className="entry value-minus">&nbsp;</div>
                                                <div className="entry value"><span>{item.contact}</span></div>
                                                <div className="entry value-plus active">&nbsp;</div>
                                            </div>
                                        </div>
                                    </td><td className="invert">
                                        <div className="rem">
                                            <div className="close1">&times; </div>
                                            
                                        </div>
    
                                    </td>
                                </tr>
                                ))} 
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
</div>
        

        
        </>
    )
}
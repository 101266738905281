import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function StudentPost(){

    
    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        post: '',
        club_image: '',
      });
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
              
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          axios.get(`https://gxd6784.uta.cloud/backend/apinew/v2/getpost.php`)
          .then(res => {
            console.log(res.data.user)
            setuser(res.data.user);
          })
        } catch (error) { throw error;}    
      }
    return (
        <>
          <div className="row">
        <div className="one-thirds">
            <div className="row">
                <div>
                    <p className="text-center ">Student Posts</p>
                {isuser.map((item,index)=>(

                <div style={{marginBottom: '5px'}}>
                    <div className="box">
                        <div className="box-body">
                        <h2>{item.user}</h2>
                        <h4>{item.post_title}</h4>
                        <p>{item.post}</p>
                        </div>
                    </div>
                </div>  
                ))}  
            </div>
                
                <div></div>
            </div>
            
        </div>
      
    </div>
        </>
    )
 }
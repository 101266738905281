import React  from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Student_Blog()
{
    const newstyle={
        fontWeight:'bold',
        
        fontFamily: 'sans-serif'
    }
    const fonts={
        fontFamily: 'sans-serif',
        fontSize:'150%'
    }


    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
    
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          axios.get(`https://gxd6784.uta.cloud/backend/apicrud/clubs.php`)
          .then(res => {
            console.log(res.data.userlist.userdata)
            setuser(res.data.userlist.userdata);
          })
        } catch (error) { throw error;}    
      }
    
      const deleteConfirm = (id) => {
        if (window.confirm("Are you sure?")) {
          deleteUser(id);
        }
      };
      const deleteUser = async (id) => {
        try {
          axios.post(`https://gxd6784.uta.cloud/snehith/apicrud/deleteusers.php`, { 
            userids: id,
          })
          .then(res => {
            setuser([]);
            alluser();
            return;
           })
        } catch (error) { throw error;}    
      }
    


/////////////////



    const navigate = useNavigate();
    const [userInfo, setuserInfo] = useState({
      club_name: '',
      club_image: '',
    });
    const onChangeValue = (e) => {
      setuserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 
    // Inserting a new user into the Database.
    const submitUser = async(event) => {
      try {
        event.preventDefault();
        event.persist();
       
        axios.post(`https://gxd6784.uta.cloud/backend/apicrud/join_club.php`, { 
            club_name: userInfo.club_name,
          image: userInfo.club_image,
        })
        .then(res => {
          console.log(res.data);
          navigate(`/`);
          return;
         })
      } catch (error) { throw error;}    
    }


//////////
      

    return (
        <>
    <div>
        <h3 className="header text-center">Select any club to join or leave the club</h3>
    
    </div>
            <div className="row">
            {isuser.map((item,index)=>(
                <div className="field button" key={item.id} >
                    <img src={item.image} className="logo"  alt="" />
                    <input type="submit" className="full" value={item.club} />
                    
                </div>
                
            
            ))}
        
        
    </div>
    <div className="row">
        <div></div>
        
        <div className="button">
            <input type="submit" className="full" value="Join now" />
            <input type="submit" className="full" value="Leave" />
        </div>
        <div></div>
        </div>
        <div className="row">
            <div className="one-fourth">
            </div>
                <div className="box">
                    <div className="form login">
                    <form  onSubmit={submitUser}>
                            <h4 className="text-center header">Want to add a club?</h4>
                        
                        <div className="field input">
    <input type="text" name="club_name" placeholder="Enter Club name" id="club_name" onChange={onChangeValue} autoComplete="off" required/>
                        </div>
                        <div className="field input">
                            <input type="file" name="club_image"  onChange={onChangeValue}  autoComplete="off" required/>
                        </div>
                        
                        <div className="field button">
                            <div className="row"> 
                                
                                <input type="submit" value="Add Now" style={{marginRight:'10px',paddingLeft:'0px'}} />
                            </div>
                           
                        </div>
                    </form>
                    </div>
                </div>
                
                    <div className="box" style={{marginLeft:'5px'}}>
                        <div className="form login">
                        <form action="">
                                <h4 className="text-center header">Want to delete a club?</h4>
                            <div className="field input">
                                <input type="text" name="id" placeholder="Enter Club name" />
                            </div>
                            <div className="field button">
                                <div className="row"> 
                                    <a href="/Student_club" style={{marginRight:'10px',paddingLeft:'0px'}}>Delete Club</a>
                                
                                </div>
                               
                            </div>
                        </form>
                        </div>
                    </div>
                    
                    <div>
                </div>
                <div className="chat">
            
                    <div className="input button">
                        <input type="submit" value="Chat with an expert" />
        
                    </div>
            </div>
    </div>
</>
    )
    }
import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Advertisement(){

    
    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        post: '',
        club_image: '',
      });
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
              
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          let result=await fetch('https://gxd6784.uta.cloud/backend/api/Advertisement',{method: 'POST'});
          result = await result.json();
          setuser(result);
        } catch (error) { throw error;}    
      }
    return (
        <>
          <div className="row">
        <div className="one-thirds">
            <div className="row">
                <div>
                    <p className="text-center ">Advertisements</p>
                {isuser.map((item,index)=>(

                <div style={{marginBottom: '5px'}}>
                    <div className="box">
                        <div className="box-body">
                        <h2>{item.advertisement}</h2>
                        <p><img style={{width:'200px'}} src={"https://gxd6784.uta.cloud/backend/public/product/"+item.image} /></p>
                        {/* <h4>$590</h4> */}
                        </div>
                    </div>
                </div>  
                ))}  
            </div>
                
                <div></div>
            </div>
            
        </div>
      
    </div>
        </>
    )
 }
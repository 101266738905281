export default function About() {
    return (
      <>
      
      <div className="half">
                
                <h1>About Us</h1>
                <p>
                                           
                Our marketplace offers consumers a fantastic opportunity to buy, sell, and 
                return goods. We have four different categories of users: students, business 
                owners, school administrators, and super admins. A student has the chance to 
                submit a product that will be seen by everyone if they have something to sell. 
                There are clubs that students can join to learn more. We have blogs as well.
                The owner of the business has the option to add or remove products and can also 
                view the payment history. They are also able to publish adverts for both new 
                and used goods. Students can monitor their classNamemates' activity. 
                
                    
                
                </p>
           
            
                
        </div></>
    )
    
  }
import React from "react";
export default function Payment_success(){
    return (
        <>
        <div className="ads-grid_shop">
            <div className="shop_inner_inf">
                <div className="privacy about row">
                    <center>
                    <img src="Paymentsuccessful21.png" alt=""/>
                </center>
                </div>
            </div>

            
        </div>
        </>
    )
}

import React from "react"
export default function Home() {
    // return 
    return (
        <>
        <div className="indexbody">
 <div className="heading">
            <h1 className="">Welcome to the Marketplace....!!!</h1>
            

       
       
        <div className="chat">
            
            <div className="input button">
                <input type="submit" value="Chat with an expert" />

            </div>
        </div>
        
        </div>
        </div>    
         </>
      ) 
  }
import React from 'react'
import { useAPi } from '../hooks/useApi';

const URL = 'https://jsonplaceholder.typicode.com/users'

const Table = () => {
  const { data, removeData } = useAPi(URL)

  const renderHeader = () => {
    let headerElement = ['id', 'name', 'email', 'phone', 'operation']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  const renderBody = () => {
    return (
      data &&
      data.map(({ id, name, email, phone }) => {
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{name}</td>
            <td>{email}</td>
            <td>{phone}</td>
            <td className="operation">
              <button className="button" onClick={() => removeData(id)}>
                Delete
              </button>
            </td>
          </tr>
        )
      })
    )
  }

  return (
    <>
      <h1 id="title" style={{textAlign:'center'}}>Report</h1>
      <div className="row">
            <div className="shop_inner_inf">
                <div className="privacy about">
                    
                    <div className="checkout-right">
                        <table className="timetable_sub" id="employee">
        
        <thead>
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
      </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default Table;
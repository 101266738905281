import React from "react";

export default function Services()
{
    return (
        <>
        <div>
        <h3 className="header text-center">Services</h3>
    
    </div>
            <div className="row">
                <div className="field button">
                    <div className="field button">
                        <img src="buysell.webp" className="logo"  alt="" />
                        <input type="submit" className="full" value="Buy and Sell" />
                        
                    </div>
                    
                </div>
                <div className="field button">
                    <img src="college-clubs-concept-1.png" className="logo"  alt="" />
                    <input type="submit" className="full" value="Clubs" />
                    
                </div>
                <div className="field button">
                    <img src="blog.jpg" className="logo"  alt="" />
                    <input type="submit" className="full" value="Blogs" />
                    
                </div>
                
                <div className="field button">
                    <div className="field button">
                        <img src="ads.webp" className="logo"  alt="" />
                        <input type="submit" className="full" value="Post Advertisements"/>
                        
                    </div>
                    
                </div>
            
        
        
        
    </div>
    <div className="row">
        <div></div>
        
        <div></div>
        </div>
        <div className="row">
            <div className="one-fourth">
            </div>
                
                    
                    <div>
                </div>
 </div>       </>
    )
}
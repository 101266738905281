
// import Header from "./components/Header"
import Pricing from "./components/Pricing";
import Home from "./components/Home";
import About from "./components/About";
import { Navigate, Route, Routes } from "react-router-dom";
import { RedirectFunction } from "react-router-dom";
import { redirect } from "react-router-dom";
import Header from "./components/Header";
import "./style.css"
import "./newstyle.css";
import Students from "./components/Students";
import Login from "./components/Login";
import Register from "./components/Register";
import Student_profile from "./components/Student_profile";
import Student_club from "./components/Student_club";
import Checkout from "./components/Checkout";
import Payment from "./components/Payment";
import Payment_success from "./components/Payment_success";
import SuperAdmin from "./components/SuperAdmin";
import InventoryAvailable from "./components/InventoryAvailable";
import StudentPost from "./components/Student_post";
import StudentOrder from "./components/Student_Order";
import SchoolAdmin from "./components/SchoolAdmin";
import BusinessOwner from "./components/BusinessOwner";
import Contact from "./components/Contact";
import Services from "./components/Service";
import forgotPass from "./components/forgotpassword";
import Table from "./components/Report";
import useToken from './components/token';
import useRoles from "./components/Roles";
import Logout from "./components/Logout";
import Cart from "./components/Cart";
import Student_Blog from "./components/Student_Blog";
import Add_Advertisement from "./components/AddAdvertisement";
import Advertisement from "./components/Advertisements";
import VerifyOTP from "./components/VerifyOTP";
import ForgotPassword from "./components/forgotpassword";
import Forgotpassword from "./components/forgotpassword";
import NewReport from "./components/newreport";
import barcharts from "./components/barchart";
import newChart from "./components/newChart";
import NewChart from "./components/newChart";
import Transactions from "./components/Traansactions";
import Studentclubs from "./components/StudentClubs";
import VerifyEmailOTP from "./components/verifyEmailOTP";
import Users from "./components/Users";
import Business from "./components/Business";
import StudentProfile from "./components/StudentProfile";
import StudentChat from "./components/chatapp/users";
import SuperAdminChat from "./components/chatapp/SuperAdmin";
function App() {

  const { token, setToken } = useToken();
  const role = localStorage.getItem('role');
  const userrole = (role);
  
  let newurl=window.location.pathname;
  if(!userrole && newurl!='/Register' &&   newurl!='/' && newurl!='/Contact' && newurl!='/about' && newurl!='/VerifyOTP' && newurl!='/forgotpass' && newurl!='/verifyEmailOTP') {
    return   <div>
    <Header/>
    <Login />
    </div>
    
  }
  

  return (
    
    <div className="">
       <Header/>
      
{/* <Home/> */}
      <div className="container">
   
        <Routes>
   <Route path="/users" element={<Users/>}/>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="Student" element={<StudentProfile />} />
          
          <Route path="/login"  element={()=>token?<Home/>:<Login/>} />
          <Route path="/Register" element={<Register/>} />
          <Route path="/Student_profile" element={<Student_profile/>} />
<Route path="/Student_club" element={<Student_club/>}/>
<Route path="/Checkout" element={<Checkout />} />
<Route path="/Payment" element={<Payment />} />
<Route path="/Payment_success" element={<Payment_success/>}/>
{userrole==1 && 
<Route path="/SuperAdmin" element={<SuperAdmin/>}/>
}
<Route path="/VerifyOTP" element={<VerifyOTP/>}/>
<Route path="/InventoryAvailable" element={<InventoryAvailable/>}/>
<Route path="/StudentPost" element={<StudentPost/>} />
<Route path="/StudentOrder" element={<StudentOrder/>}/> 
{userrole!=4  &&  

<Route path="/SchoolAdmin" element={<SchoolAdmin />}/>
}
<Route path="/newreport" element={<NewReport/>}/>
<Route path="/BusinessOwner" element={<BusinessOwner/> }/>
<Route path="/Contact" element={<Contact/>} />
<Route path="/Services" element={<Services/>}/>
<Route path="/forgotpass" element={<Forgotpassword/>}/>
<Route path="/Report" element={<Table/>}/>
<Route path="/Logout" element={<Logout/>}/>
<Route path="/Cart" element={<Cart/>}/>
<Route path="/StudentChat" element={<StudentChat/>} />
<Route path="/SuperAdminChat" element={<SuperAdminChat/>}/>
{/* <Route path="/Students" element={<Students/>}/> */}
<Route path="/Student_Blog" element={<Student_Blog/>} />
<Route path="/Add_Advertisement" element={<Add_Advertisement/>} />
<Route path="/Advertisement" element={<Advertisement />}/>

<Route path="/reports" element={<NewChart />}/>
<Route path="/transactions" element={<Transactions/>} />
<Route path="/StudentClubs" element={<Studentclubs/>}/>
<Route path="/verifyEmailOTP" element={<VerifyEmailOTP/>}/>
<Route path="/Business" element={<Business/>} />
         </Routes>
      </div>
      
  
    </div>
    
  )
}

export default App
import React  from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Add_Advertisement()
        {

          useEffect( () => {
            window.scrollTo(0, 0);
            alluser();
          }, []); 
          const navigate = useNavigate();
          const [userInfo, setuserInfo] = useState({
            advertisement: '',
            image: '',
          });
          const onChangeValue = (e) => {
            setuserInfo({
              ...userInfo,
              [e.target.name]:e.target.value
            });
          } 
                  
          const [isuser, setuser] = useState([]);
          const alluser = async (ids) => {
            try {
              axios.get(`https://gxd6784.uta.cloud/backend/apicrud/products.php`)
              .then(res => {
                console.log(res.data.userlist.userdata)
                setuser(res.data.userlist.userdata);
              })
            } catch (error) { throw error;}    
          }
          const [advertisement, setname] = useState("")
          const [image, setimage] = useState("")
          
          const submitUser = async(event) => {
            try {
              event.preventDefault();
              event.persist();
              console.warn(advertisement, image);
              const formdata = new FormData();
              formdata.append('file', image);
              formdata.append('advertisement', advertisement);
              
              let result = await fetch("https://gxd6784.uta.cloud/backend/api/addAdvertisement", {
                  method: 'POST',
                  body: formdata, 
              });
              alert("Data stored");
              // window.location.reload();
      
              
            } catch (error) { throw error;}    
          }
      
      
          async function add() {
      
              
          }

            const newstyle={
                fontWeight:'bold',
                
                fontFamily: 'sans-serif'
            }
            const fonts={
                fontFamily: 'sans-serif',
                fontSize:'150%'
            }
            return (
                <>
    <div className="row">
    <div className="">
    </div>
        
        <div className="box">
            <div className="form login">
            <form onSubmit={submitUser}>
                <h4 className="text-center header">Add Advertisement</h4>
                <div className="field input">
                    <input type="text" id={advertisement}
                                    onChange={(e) => setname(e.target.value)}
                                    name="advertisement"  placeholder="Advertisement"  required />

                </div>
                <div className="field input">
                    <input type="file" name="image" id={image} onChange={(e) => setimage(e.target.files[0])} />
                </div>
                
                <div className="field button">
                    <div className="row"> 
                    <input type="submit" value="Add Advertisement" style={{marginRight:'10px',paddingLeft:'0px'}} />
                    </div>
                    </div>
            </form>
            
                    
            </div>
        </div>
        
        <div>
    </div>
    
  
</div> 


<div className="row" style={{ margin:'0px',padding:'0px'}}>


</div>

    </>
            )
            }
import React from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import useToken from './useToken';
import { redirect } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';

export default function Logout()
{
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('user_id');
    window.location.href = '/';


}
import React  from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Student_profile()
        {
          const [data, setData] = useState([]);

          const navigate = useNavigate();
          const [userInfo, setuserInfo] = useState({
            post: '',
            club_image: '',
            user_id:'',
            item_id:'',
            price:'',
            post_title:''
            
          });
          const onChangeValue = (e) => {
            setuserInfo({
              ...userInfo,
              [e.target.name]:e.target.value
            });
          } 
                  
          const [isuser, setuser] = useState([]);
          useEffect(() => {
            (async () => {
              let result = await fetch("https://gxd6784.uta.cloud/backend/api/allProducts", {
                method: 'POST'
              }
              );
              result = await result.json();
              setuser(result);
              
            })();
          }, [])
        




          const submitUser = async(event) => {
            try {
              event.preventDefault();
              event.persist();
              let item={post: userInfo.post,
                post_title:userInfo.post_title,
              user_id: localStorage.getItem('id')};
              let result = await fetch("https://gxd6784.uta.cloud/backend/api/post", {
       method: 'POST',
       body: JSON.stringify(item),
       headers: {
         "Content-Type": 'application/json',
         "Accept": 'application/json '
       }
     })
     if(result)
     {
      window.alert('Post Added Successfully');
     }
     console.log(result)

            } catch (error) { throw error;}    
          }
      
          const addtocart = (id,price) => {
            if (window.confirm("Are you sure?")) {
              submitcart(id,price);
            }
          };

          const submitcart = async(id,price) => {
            try {
              
             console.log(userInfo)
             let item={user_id: localStorage.getItem('id'),
             item_id: id,
             price:price};
             localStorage.setItem('total', price);
             let result = await fetch("https://gxd6784.uta.cloud/backend/api/addCart  ", {
      method: 'POST',
      body: JSON.stringify(item),
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json '
      }
    })
    console.log(result)
              
            } catch (error) { throw error;}    
          }
        

            const newstyle={
                fontWeight:'bold',
                
                fontFamily: 'sans-serif'
            }
            const fonts={
                fontFamily: 'sans-serif',
                fontSize:'150%'
            }
            return (
                <>
    <div className="row">
    <div className="">
    </div>
        
        <div className="box">
            <div className="form login">
            <form onSubmit={submitUser}>
                <h4 className="text-center header">Create a Post?</h4>
                <div className="field input">
                    <input type="text" name="post_title" id="post_title" placeholder="Title" onChange={onChangeValue} required />
                </div>
                <div className="field input">
                    <input type="text" name="post" id="post" placeholder="Post" onChange={onChangeValue} required />
                </div>
                <div className="field button">
                    <div className="row"> 
                    <input type="submit" value="Add Post" style={{marginRight:'10px',paddingLeft:'0px'}} />
                    </div>
                    </div>
            </form> 
            
                    <div className="field button">
                
                    <div className="row"> 
                        <a href="/Student_club" style={{marginRight:'10px',paddingLeft:'0px'}}>Join Clubs</a>
                    </div>
                    <div className="row"> 
                        <a href="/Advertisement" style={{marginRight:'10px',paddingLeft:'0px'}}>Advertisements</a>
                    </div>
                    </div>
            </div>
        </div>
        
        <div>
    </div>
    
  
</div> 


<div className="row" style={{ margin:'0px',padding:'0px'}}>

<div>
    <h3 className="header text-center">Products</h3>

</div>

</div>
<div className="row padding_25" >

{isuser.map((item,index)=>(
  
  <div className="box one-fourths items" key={item.id}>
    
  <div className="box-body box-profile">
      <center>
      <img className=" img-responsive item_image"  src={item.image} alt="Item picture" />
    </center>
<label className="text-center search_item" style={newstyle} id="item_0">
     {item.name}<br/>
    <span className="" style={newstyle}>{item.price}</span>
    <br/>
  <div className="row">
  
    <input type="submit"  onClick={() => addtocart(item.id,item.price)} value="Add to cart" style={{marginRight:'10px',paddingLeft:'0px'}} />
    <a href="/Checkout" className="text-center"><span> Buy Now </span></a>
    
  </div>

  </label>

</div>
</div>

    
  ))}





</div>

<div className="row padding_25">

</div>
<div className="row">
  <div className="">
</div>
<div className="box">
  <div className="form login">
  <form action="">
          <h4 className="text-center header">Want to return a product?</h4>
      <div className="field input">
          <input type="text" name="id" placeholder="Enter Order ID" />
      </div>
      <div className="field button">
          <div className="row"> 
              <a href="Student_club" style={{marginLeft:'10px',paddingLeft:'0px'}}>Return</a>
          
          </div>
        </div>
        </form>
    </div>
    </div>
    <div>
      <div className="row">
    <div className="field button chat">
                <a href="https://gxd6784.uta.cloud/backend/chat/index.php">Chat With Admin
                </a>
            </div>
            </div>
    </div>
    </div>
    </>
            )
            }
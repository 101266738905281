import React from "react";
export default function SuperAdmin()
{
    const styles={
        width: '100%'
    }
    return (
        <>
        <h2 className="text-center">Super Admin</h2>
    <div className="row">
        <div className="full">
            <div className="row">
                <div className=""></div>
                <div className="field button  ">
                    <p className="text-center ">Students</p>
                <div className="row">
                    <a href="/StudentOrder">Order History</a>
                </div>
                <div className="row">
                    <a href="/StudentPost">Posts</a>
                </div>
                <div className="row">
                    <a href="/StudentClubs">Clubs</a>

                </div>
                    
                <div className="row">
                    <a href="/users">Students</a>

                </div>
                </div>
                <div className="field button ">
                    <p className="text-center">School Admin</p>
                
                    <div className="row">
                        <a href="/Advertisement">
                        Advertisements
                        </a>
                    </div>
                    
                    <div className="row">
                        <a href="/transactions">
                        Payment  History
                        </a>
                    </div>
                    
                </div>  
                <div className="field button ">
                    <p className="text-center">Business Owner</p>
                
                    <div className="row">
                        <a href="/Advertisement">Advertisements</a>
                    </div>
                    
                    <div className="row">
                        <a href="/InventoryAvailable">Inventory</a>
                    </div>
                    <div className="row">
                        <a href="/transactions">Payment History</a>
                    </div>
                    
                    <div className="row">
                        <a href="/reports">Reports</a>
                    </div>
                    
                <div className="row">
                    <a href="/Business">BusinessOwner</a>

                </div>
                </div>
                <div></div>
                <div className="chat">
                <a href="https://gxd6784.uta.cloud/backend/chat/index.php" >Chat with Student</a>
                    
            </div>
            
        </div>
        
        
    </div>
    </div>
        </>
    )
}
import React from "react"
import  {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";

export default function InventoryAvailable()
{

    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const [count, setCount] = useState(0)  

    
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          axios.get(`https://gxd6784.uta.cloud/backend/apicrud/products.php`)
          .then(res => {
            console.log(res.data.userlist.userdata)
            setuser(res.data.userlist.userdata);
          })
        } catch (error) { throw error;}    
      }
    
      const deleteConfirm = (id) => {
        if (window.confirm("Are you sure?")) {
          deleteUser(id);
        }
      };
      const deleteUser = async (id) => {
        try {
          axios.post(`https://gxd6784.uta.cloud/snehith/apicrud/deleteusers.php`, { 
            userids: id,
          })
          .then(res => {
            setuser([]);
            alluser();
            return;
           })
        } catch (error) { throw error;}    
      }

    return (
        <>
         <div className="row">
        <div className="one-thirds">
            <div className="row">
                <h2 className="">Inventory Available</h2>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Price</th>
                            <th>Add</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    {isuser.map((item,index)=>(


<tr>
                        <td>{item.name}</td>
                        <td>{item.stock} left</td>
                        <td className="add"><button className="button">Add Item</button></td>
                        <td className="delete"><button className="button">Delete Item</button></td>
                    </tr>


))}
               </table>

            </div>
        </div>
        
    </div>
        </>
    )
}
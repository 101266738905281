import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Forgotpassword()
{
    const [useremail, setEmail] = useState([]);
  let temp = 0;
  async function handleRecovery(){
    let item = { useremail }; 
    try{
      let result = await fetch("https://gxd6784.uta.cloud/backend/api/PasswordReset", {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json '
        }
      })
      result = await result.json(item);
      console.log(result);
      temp = result;

    }
    catch(e){
    }
    if(temp === 1){
      alert('Recovery Email Sent');
       window.location.reload();
    }
    else{
      alert('no such email exists');
    }
    
  }
  
    return (
        <>
        <div className="row">
        <div className=""></div>
        <div className="">
            <div className="box">
                <div className="form login">
                <form action="">
                    <h4 className="text-center header">Forgot Password</h4>
                    <div className="field input">
                        <label for="">Email</label>
                        <input type="text" className="input" placeholder="a" value={useremail} onChange={(e)=>setEmail(e.target.value)}/>
        
                    </div>
                    <div className="field button">
                    <input type="button" value="SUBMIT" className="submitBtn" onClick={handleRecovery} />
       
                    </div>
                    </form>
                    </div>
                    </div>
                    </div>
                    <div></div>
                    </div>
        </>
    )
}
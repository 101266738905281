import React from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import useToken from './useToken';
import { redirect } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';

export default function Login({ setToken })
{

let result;
  const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({
        email: '',
        password: '',
      });

      const [count, setCount] = useState(0)  
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
      // const [token, setToken] = useState(getToken());
      const [email, setEmail] = useState("");
      const [password, setPassword] = useState("");
      
      // Inserting a new user into the Database.
      const submitUser = async(event) => {
        try {
          event.preventDefault();
          event.persist();
          
          let item = { email, password };
          result = await fetch("https://gxd6784.uta.cloud/backend/api/login", {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
              "Content-Type": 'application/json',
              "Accept": 'application/json '
            }
            
          })
          result = await result.json(item);

        
          if(result === 0){
            alert("invalid Credentials");
            localStorage.clear();
            localStorage.removeItem("role");
            navigate('/login');
      
          }
          localStorage.setItem("user-info", JSON.stringify(result))
    localStorage.setItem("role", JSON.stringify(result.role))
    localStorage.setItem("id",JSON.stringify(result.user_id))
    
          console.warn(result)
      
          if (result.role === 4) {
           window.location.href='/Student_profile';
          }
          else if (result.role === 2) {
            window.location.href='/SchoolAdmin';
          }
          else if (result.role === 3) {
            window.location.href='/BusinessOwner/';
          }
          else if (result.role === 1) {
            window.location.href='/SuperAdmin';
          }
        } catch (error) { throw error;
        }    
      }
  


    return (
        <>
<div className="row">
    <div className=""></div>
    <div className="">
        <div className="box">
            <div className="form login">

            {/* <h1 className="count">{count}</h1> */}


            {/* <button onClick={() => setCount(count + 1)}>Up</button> */}
            {/* <button onClick={() => setCount(count - 1)}>Down</button> */}

            <form action="" onSubmit={submitUser}>
                <h4 className="text-center header">Login Form</h4>
                <div className="field input">
                    <label for="">ID</label>
                    <input type="email" name="email" required placeholder="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <div className="field input">
                    <label for="">Password</label>
                    <input type="password" name="password" required placeholder="*****" value={password} onChange={(e)=>setPassword(e.target.value)} />
                </div>
                <div className="field button">
                <input type="submit" value="Login" style={{marginRight:'10px',paddingLeft:'0px'}} />  

                </div>
                <div className="pass_link">Forgot password? <a href="./forgotpass">Click to reset your password</a></div>
                <div className="signup_link"> New User?  <a href="./Register">SignUp</a>

                
                </div>    
            </form>
            </div>
        </div>
    </div>
    <div></div>
    
</div>
</>
 )
 }
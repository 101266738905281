import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Checkout()
{
    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
      }, []); 
      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        post: '',
        club_image: '',
      });
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
              
      const [isuser, setuser] = useState([]);
      const [total, settotal] = useState([]);
      
      const alluser = async (ids) => {
        try {
          let datas={
            user_id:localStorage.getItem('id')
          }
          let result = await fetch("https://gxd6784.uta.cloud/backend/api/getcart", {
              method: 'POST',
              body: JSON.stringify(datas),
              headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json '
              }
            }
            );
            result = await result.json(datas);

            setuser(result);
            // settotal(res.data.total);
            console.log(JSON.stringify(result))

        } catch (error) { throw error;}    
      }
      const [count, setCount] = useState(0);
      const deleteConfirm = (id) => {
        if (window.confirm("Are you sure?")) {
          deleteUser(id);
        }
      };
      const deleteUser = async (id) => {
        try {
          let datas={cart_id:id}
          let result = await fetch("https://gxd6784.uta.cloud/backend/api/deletecart", {
              method: 'POST',
              body: JSON.stringify(datas),
              headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json '
              }
            }
            );
          console.log(result)
        } catch (error) { throw error;}    
      }
    return (
        <>
        
        <div className="row">
            <div className="shop_inner_inf">
                <div className="privacy about">
                    <h3>Chec<span>kout</span></h3>
    
                    <div className="checkout-right">
                        <h4>Your shopping cart </h4>
                        <table className="timetable_sub">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quality</th>
                                    <th>Product Name</th>
    
                                    <th>Price</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>

                            {isuser.map((item,index)=>(

                                <tr className="rem1">
                                    <td className="invert-image"><a href=""><img src={item.image} alt=" " className="item_image img-responsive" /></a></td>
                                    <td className="invert">
                                        <div className="quantity">
                                            <div className="quantity-select">
                                                <div className="entry value-minus">&nbsp;</div>
                                                <div className="entry value"><span>1</span></div>
                                                <div className="entry value-plus active">&nbsp;</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="invert">{item.name}</td>
                                   
                                    <td className="invert" >{item.price}</td>
                                    {/* {settotal(item.price)} */}
                                     <h1 className="count">{count}</h1>
                                     {() => setCount(count + item.price)}

            {/* <button onClick={() => setCount(count + 1)}>Up</button> */}
            {/* <button onClick={() => setCount(count - 1)}>Down</button> */}
                                    <td className="invert">
                                        <div className="rem">
                                            <div className="close1" onClick={() => deleteConfirm(item.id)}>&times; </div>
                                            
                                        </div>
    
                                    </td>
                                </tr>
                                ))} 
<tr><td></td><td></td><td>Total:</td><td>{total}</td><td></td></tr>

                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
</div>
        

        <div className="row">
            <div className="row button">
                <a href="/Payment">Continue to Payment</a>

            </div>
        </div>
    
        </>
    )
}
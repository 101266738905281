import React  from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Student_club()
{
  const loggeduser_id=localStorage.getItem('id');
    const newstyle={
        fontWeight:'bold',
        fontFamily: 'sans-serif'
    }
    const fonts={
        fontFamily: 'sans-serif',
        fontSize:'150%'
    }


    useEffect( () => {
        window.scrollTo(0, 0);
        alluser();
        alluser1();
        alluser0();
      }, []); 
    
      const [isuser, setuser] = useState([]);
      const alluser = async (ids) => {
        try {
          (async () => {
            let result = await fetch("https://gxd6784.uta.cloud/backend/api/allclub", {
              method: 'POST'
            }
            );
            result = await result.json();
            setuser(result);
            
          })();
         
        } catch (error) { throw error;}    
      }

      const [isuser0, setuser0] = useState([]);
      const alluser0 = async (ids) => {
        try {
          (async () => {

            axios.post(`https://gxd6784.uta.cloud/backend/apicrud/fetchclub.php`, { 
             user_id:localStorage.getItem('id')
          }).then(result => {
            // setuser0(result);
            setuser0(result.data.userlist.userdata);
            console.log(result.data.userlist);
          });


            
            
            
          })();
         
        } catch (error) { throw error;}    
      }


      const [isuser1, setuser1] = useState([]);
      const alluser1 = async (ids) => {
        try {
          let item={
          id: localStorage.getItem('id')};
          (async () => {
            let result = await fetch("https://gxd6784.uta.cloud/backend/api/listClubbyid", {
              method: 'POST',
              body: JSON.stringify(item),
              headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json '
              }
            }
            );
            result = await result.json();
            setuser1(result);
            console.log(result)

          })();
         

        } catch (error) { throw error;}    
      }
    
      
    


/////////////////



    const navigate = useNavigate();
    const [userInfo, setuserInfo] = useState({
      club_name: '',
      club_image: '',
      join_club_name:'',
      leave_club_name:'',
      delete_club_name:''

    });
    const onChangeValue = (e) => {
      setuserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 
    // Inserting a new user into the Database.
    const [club_name, setname] = useState("")
    const [club_image, setimage] = useState("")
    
    const submitUser = async(event) => {
      try {
        event.preventDefault();
        event.persist();
        console.warn(club_name, club_image);
        const formdata = new FormData();
        formdata.append('file', club_image);
        formdata.append('advertisement', club_name);
        formdata.append('user_id',localStorage.getItem('id'));
        let result = await fetch("https://gxd6784.uta.cloud/backend/api/addclub", {
            method: 'POST',
            body: formdata, 
        });
        alert("Data stored");
        // window.location.reload();

        
      } catch (error) { throw error;}    
    }




  


    const submitJoinClub = async(event) => {
        try {
          event.preventDefault();
          event.persist();
          let datas={club_name: userInfo.join_club_name,
            user_id:localStorage.getItem('id')}
          
            let result = await fetch("https://gxd6784.uta.cloud/backend/api/joinclub", {
              method: 'POST',
              body: JSON.stringify(datas),
              
         headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json '
        }
            }
            );
          
          
        } catch (error) { throw error;}    
      }
  

      const submitLeaveClub = async(event) => {
        try {
          event.preventDefault();
          event.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/student_leave_club.php`, { 
              club_name: userInfo.leave_club_name,
              user_id:localStorage.getItem('user_id')
          })
          .then(res => {
            console.log(res.data);
            navigate(`/`);
            return;
           })
        } catch (error) { throw error;}    
      }
  
//////////
      
const deleteConfirm = (id) => {
    if (window.confirm("Are you sure?")) {
      deleteUser(id);
    }
  };
  const deleteUser = async (id) => {
    try {
      let datas={ club_id: id}
      let result = await fetch("https://gxd6784.uta.cloud/backend/api/leaveclub", {
        method: 'POST',
        body: JSON.stringify(datas),
        
   headers: {
    "Content-Type": 'application/json',
    "Accept": 'application/json '
  }
      }
      );


    } catch (error) { throw error;}    
  }


  /////

  const deleteClubConfirm = (id) => {
    if (window.confirm("Are you sure?")) {
      deleteClub(id);
    }
  };
  const deleteClub = async (id) => {
    try {
      let datas={ club_id: id}
      let result = await fetch("https://gxd6784.uta.cloud/backend/apicrud/deleteclub.php", {
        method: 'POST',
        body: JSON.stringify(datas),
        
   headers: {
    "Content-Type": 'application/json',
    "Accept": 'application/json '
  }
      }
      );
      result = await result.json(datas);
      if(result.success==true)
      {
        alert('Deleted Sucessfully');
      }
console.log(result);
    } catch (error) { throw error;}    
  }


////

    return (
        <>
    <div>
        <h3 className="header text-center">Select any club to join or leave the club</h3>
    
    </div>
            <div className="row">
            {isuser.map((item,index)=>(
                <div className="field button" key={item.id} >
                    <img src={"https://gxd6784.uta.cloud/backend/public/product/"+item.image} className="logo"  alt="" />
                    <input type="submit" className="full" value={item.club} />
                    
                </div>
                
            
            ))}
        
        
    </div>
    <div className="row">
        
        <div></div>
        <table className="timetable_sub">
            
                            <thead>
                                <tr>
                                    <th>Club Name</th>
                                    <th>Leave Club</th>
                                </tr>
                            </thead>
                            <tbody>
                            {isuser1.map((item1,index)=>(

                                <tr className="rem1">
                                    <td className="invert">{item1.club_name}</td>
    
                                    <td className="invert">
                                        <div className="rem">
                                            <div className="close1" onClick={() => deleteConfirm(item1.id)}>&times; </div>
                                            
                                        </div>
    
                                    </td>
                                </tr>
                                ))} 

                            </tbody>
                        </table>
        
                        <table className="timetable_sub">
            
            <thead>
                <tr>
                    <th>Club Name</th>
                    <th>Delete Club</th>
                </tr>
            </thead>
            <tbody>
            {isuser0.map((item,index)=>(

              <tr className="rem1">
                    <td className="invert">{item.club}</td>

                    <td className="invert">
                        <div className="rem">
                            <div className="close1" onClick={() => deleteClubConfirm(item.id)}>&times; </div>
                            
                        </div>

                    </td>
                </tr>
                ))} 

            </tbody>
        </table>
        <div></div>
        </div>
        <div className="row">
        <div className="box" style={{marginRight:'5px'}}>
                
            <div className="">
            <div className="form login">
                    <form  onSubmit={submitJoinClub}>
                    <h4 className="text-center header">Want to Join a club?</h4>
                         
                <div className="field input">
                    <input type="text" name="join_club_name" placeholder="Enter Club name" id="join_club_name" onChange={onChangeValue} autoComplete="off" required/>
                </div>
                        
        <div className="button">
            <input type="submit" className="full" value="Join now"  />
        </div>
</form>


</div>
</div>
            </div>
                <div className="box">
                    <div className="form login">
                    <form  onSubmit={submitUser}>
                            <h4 className="text-center header">Want to add a club?</h4>
                        
                        <div className="field input">
    <input type="text" id={club_name}
                                    onChange={(e) => setname(e.target.value)}
                                    name="club_name"  placeholder="Advertisement"  required />
                        </div>
                        <div className="field input">
                            <input type="file" name="club_image" id={club_image} onChange={(e) => setimage(e.target.files[0])} />
                        </div>
                        
                        <div className="field button">
                            <div className="row"> 
                                
                                <input type="submit" value="Add Now" style={{marginRight:'10px',paddingLeft:'0px'}} />
                            </div>
                           
                        </div>
                    </form>
                    </div>
                </div>
                
                    <div className="box" style={{marginLeft:'5px'}}>
                        <div className="form login">
                        <form action="">
                                <h4 className="text-center header">Want to delete a club?</h4>
                            <div className="field input">
                                <input type="text" name="delete_club_name" id="delete_club_name" placeholder="Enter Club name" />
                            </div>
                            <div className="field button">
                                <div className="row"> 
                                <div className="close1" onClick={() => deleteClubConfirm(1)}>&times; </div>
                                
                                </div>
                               
                            </div>
                        </form>
                        </div>
                    </div>
                    
                    
                <div className="chat">
            
                    <div className="input button">
                        <input type="submit" value="Chat with an expert" />
        
                    </div>
            </div>
    </div>
</>
    )
    }
import React from "react";
import {useEffect,useState} from 'react'
import axios from 'axios';
import {Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function StudentChat()
{
    useEffect( () => {
        window.scrollTo(0, 0);
        // alluser();
      }, []); 
      const navigate = useNavigate();
      const [userInfo, setuserInfo] = useState({
        message: '',
        image: '',
      });
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
              
  

      /////////////////////


      const submitUser = async(event) => {
        try {
          
          event.preventDefault();
          event.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apicrud/insertmessage.php`, { 
              message:userInfo.message,
              user_id:localStorage.getItem('id')
          })
          .then(res => {
            console.log(res)
      
        })
    }
    catch
    {

    }
}

    return (
        <>
 <div className="row">
    <div className="">
    </div>
        
        <div className="box">
            <div className="form login">
            <form onSubmit={submitUser}>
                <h4 className="text-center header">Chat With Super SuperAdmin</h4>
                <div className="field input">
                    <input type="text" 
                                    onChange={onChangeValue}
                                    name="message"  placeholder="Message"  required />

                </div>
                
                
                <div className="field button">
                    <div className="row"> 
                    <input type="submit" value="Send Message" style={{marginRight:'10px',paddingLeft:'0px'}} />
                    </div>
                    </div>
            </form>
            
                    
            </div>
        </div>
        
        <div>
    </div>
    
  
</div> 


<div className="row" style={{ margin:'0px',padding:'0px'}}>


</div>

  </>
  )
}
import React from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import useToken from './useToken';
import { redirect } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {omit} from 'lodash'

export default function Register()
{
  const [values, setValues] = useState({});
  //Errors
  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {
    //A function to validate each input values

    switch (name) {
    
        case 'email':
            if(
                !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
            ){
                setErrors({
                    ...errors,
                    email:'Enter a valid email address'
                })
            }else{

                let newerrorob = omit(errors, "email");
                setErrors(newerrorob);
                
            }
        break;
        
        case 'password':
            if(
                !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
            ){
                setErrors({
                    ...errors,
                    password:'Password should contains  8 charaters ,  uppercase,lowercase and numbers'
                })
            }else{

                let newerrorob = omit(errors, "password");
                setErrors(newerrorob);
                
            }
        break;
        
        default:
            break;
    }
}





    const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({
        email: '',
        password: '',
        first_name:'',
        last_name:'',
        contact:'',
        username:'',
        role:''  
      });

      const [count, setCount] = useState(0)  
      const onChangeValue = (event) => {
        event.persist();

        let name = event.target.name;
        let val = event.target.value;
        
        validate(event,name,val);

        
        
        
        
    
      setuserInfo({
        ...userInfo,
        [event.target.name]:event.target.value
      });
    
      } 
      const submitUser = async(event) => {
        try {
          
          event.preventDefault();
          event.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/create-user-api.php`, { 
              email: userInfo.email,
            password: userInfo.password,
            username:userInfo.first_name+userInfo.last_name,
            role:4,
            contact:userInfo.contact,
            name:userInfo.first_name+userInfo.last_name
          })
          .then(res => {
            if(res.data.status==1)
            {
                axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/sendemailotp.php`, { 
              email: userInfo.email,
          })
          .then(response => {
            console.log(response)
            if(response.data.status==1)
            {
                window.location.href='/verifyEmailOTP?email='+userInfo.email;
          
            }
          }
          );
                
            }
            else
            {

            }
            alert(res.data.message);
          })
        }
        catch (error) { throw error;}    
    }
            

return (
    <>
    <div className="row">
            <div className="one-fourth"></div>
            <div className="box one-half">
                <div className="form login">
                <form onSubmit={submitUser}>
                    <h4 className="text-center header">Signup Form</h4>
                    <div className="field input">
                        <label for="">First Name</label>
                        <input type="text" name="first_name" id="first_name" placeholder="first name" onChange={onChangeValue}/>
                    </div>
                    <div className="field input">
                        <label for="">Last Name</label>
                        <input type="text" name="last_name" id="last_name" placeholder="Last Name" onChange={onChangeValue}/>
                    </div>
                    <div className="field input">
                        <label for="">Email</label>
                        <input type="email" name="email" id="email" placeholder="email@email.com"  onChange={onChangeValue} required/>
                        {
        errors.email && 
        
        <h3 style={{color:"red"}}>{errors.email}</h3>

      }
                    </div>
                    <div className="field input">
                        <label for="">Confirm Password</label>
                        <input type="password"  minLength={8} name="password" id="password" placeholder="*****" onChange={onChangeValue} required/>
                        {
        errors.password && 
        
        <h3 style={{color:"red"}}>{errors.password}</h3>

      }
      
                    </div>
                    <div className="field input">
                        <label for="">Contact Information</label>
                        <input type="text" name="contact" id="contact" placeholder="+1999999999" required onChange={onChangeValue}/>

                    </div>
                    <div className="field button">
                <input type="submit" value="Register" style={{marginRight:'10px',paddingLeft:'0px'}} />  

                </div>
                
                </form>
                </div>
            </div>
            <div></div>
        </div>
        <div></div>
  
    </>
)
}
import React from "react";
export default function SchoolAdmin()
{
    return (
        <>
        <div className="row">
        <div className="">
            <div className="row">
                <div className="field button">
                    
                </div>
                <div className="field button  ">
                    <p className="text-center ">Students</p>
                    <div className="row"> 
                        <a href="/StudentOrder" className="full">Order History</a>
                        
                    </div>
                <div className="row">
                    <a href="StudentPost" className="full">Posts</a>
                </div>
                <div className="row">
                    <a href="/Studentclubs" className="full">Clubs</a>
                </div>
                    
            </div>
                <div className="field button ">
                    <p className="text-center">Business Owners</p>
                
                    <div className="row">
                    <a href="/Advertisement" className="full">Advertisements</a>
                </div>
                <div className="row">
                    <a href="/transactions" className="full">Payment  History</a>
                </div>
                <div className="row">
                    <a href="/reports" className="full">Reports</a>
                </div>
                </div>  
               <div></div> 
            </div>
            
        </div>
        
   
        
    </div>
    <div className="row">
    <div className="field button chat">
                <a href="https://gxd6784.uta.cloud/backend/chat/index.php">Chat With Admin
                </a>
            </div>
            </div>
        </>
    )
}
import React from "react"
import {useEffect,useState} from 'react'
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import useToken from './useToken';
import { redirect } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';

export default function VerifyOTP({ setToken })
{


  const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({
        email: '',
        otp: '',
      });

      const [count, setCount] = useState(0)  
      const onChangeValue = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
      // const [token, setToken] = useState(getToken());
          
      // Inserting a new user into the Database.
      const submitUser = async(event) => {
        try {
          event.preventDefault();
          event.persist();
         
          axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/checkotp.php`, { 
              id: localStorage.getItem('user_id'),
            otp: userInfo.otp,
          })
          .then(response => {


if(response.data.status==1)
{
   

    axios.post(`https://gxd6784.uta.cloud/backend/apinew/v2/getuser.php`, { 
              id: localStorage.getItem('user_id'),
          })
          .then(res => {
            if(res.data.status==1)
            {
          //    window.location.href='/VerifyOTP';
          
            
            //
            const logintoken=res.data.token;
            console.log(res.data);
if(res.data.user.role==1)
{
  // navigate(`/SuperAdmin`)
    window.location.href='/SuperAdmin';

}
else if(res.data.user.role==2)
{
  window.location.href=`/SchoolAdmin`
}
else if(res.data.user.role==3)
{
  window.location.href=`/BusinessOWner`
}
else if(res.data.user.role==4)
{
  window.location.href=`/Student_Profile`;
}           
          

            // const saveToken = logintoken => {
              localStorage.setItem('role', JSON.stringify(res.data.user.role));
              localStorage.setItem('email',JSON.stringify(res.data.user.email));
              localStorage.setItem('user_id',JSON.stringify(res.data.user.id));
              // setToken(logintoken);
            // };
            // setToken(logintoken);
            localStorage.setItem('token',JSON.stringify(logintoken));
              
          // window.alert('Logedin Successfully!')
            return {}
        }});

          //////
          }
          else
        {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('email');
            localStorage.removeItem('user_id');
            window.location.href = '/Login';
        
          window.alert(response.data.error)
        }
        }
        
          
          )
        } catch (error) { throw error;
        }    
      }
  


    return (
        <>
<div className="row">
    <div className=""></div>
    <div className="">
        <div className="box">
            <div className="form login">

            {/* <h1 className="count">{count}</h1> */}


            {/* <button onClick={() => setCount(count + 1)}>Up</button>
            <button onClick={() => setCount(count - 1)}>Down</button> */}

            <form action="" onSubmit={submitUser}>
                <h4 className="text-center header">Verify OTP</h4>
                <div className="field input">
                    <label for="">Password</label>
                    <input type="otp" name="otp" required placeholder="*****" onChange={onChangeValue} />
                </div>
                <div className="field button">
                <input type="submit" value="Verify OTP" style={{marginRight:'10px',paddingLeft:'0px'}} />  

                </div>
                <div className="pass_link">Forgot password? <a href="./forgotpass">Click to reset your password</a></div>
                <div className="signup_link"> New User?  <a href="./Register">SignUp</a>

                
                </div>    
            </form>
            </div>
        </div>
    </div>
    <div></div>
    
</div>
</>
 )
 }